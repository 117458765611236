import { convertSubscriptionToAPI, Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { service } from 'common/service'
import { updateMembers } from './useSubscription'

export const companyService = service
  .enhanceEndpoints({ addTagTypes: ['Company'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      createCompany: builder.mutation<Res['company'], Req['createCompany']>({
        invalidatesTags: [{ id: 'LIST', type: 'Company' }],
        queryFn: async (query, _1, _2, baseQuery) => {
          const subscription = convertSubscriptionToAPI(query.subscription)
          const { data, error } = await baseQuery({
            body: { ...subscription, members: [] },
            method: 'POST',
            url: `partners/company`,
          })
          await updateMembers(
            data.company_id,
            data.subscription_id,
            query.membersToRemove,
            query.subscription.members,
          )
          return { data, error }
        },
      }),
      getCompany: builder.query<Res['company'], Req['getCompany']>({
        providesTags: (res, _, req) => [{ id: req.id, type: 'Company' }],
        query: (query: Req['getCompany']) => ({
          url: `partners/subscriptions?company_id=${query.id}`,
        }),
      }),
      // END OF ENDPOINTS
    }),
  })

export async function createCompany(
  store: any,
  data: Req['createCompany'],
  options?: Parameters<
    typeof companyService.endpoints.createCompany.initiate
  >[1],
) {
  return store.dispatch(
    companyService.endpoints.createCompany.initiate(data, options),
  )
}
export async function getCompany(
  store: any,
  data: Req['getCompany'],
  options?: Parameters<typeof companyService.endpoints.getCompany.initiate>[1],
) {
  return store.dispatch(
    companyService.endpoints.getCompany.initiate(data, options),
  )
}
// END OF FUNCTION_EXPORTS

export const {
  useCreateCompanyMutation,

  useGetCompanyQuery,
  // END OF EXPORTS
} = companyService

/* Usage examples:
const { data, isLoading } = useGetCompanyQuery({ id: 2 }, {}) //get hook
const [createCompany, { isLoading, data, isSuccess }] = useCreateCompanyMutation() //create hook
companyService.endpoints.getCompany.select({id: 2})(store.getState()) //access data from any function
*/
