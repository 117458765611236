import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { FC, ReactNode } from 'react'

interface ModalDefault {
  title: ReactNode
  isOpen: boolean
  sideModal?: boolean
  className?: string
  onDismiss: () => void
  toggle: () => void
}

const ModalDefault: FC<ModalDefault> = ({
  children,
  className,
  isOpen,
  onDismiss,
  sideModal,
  title,
  toggle,
}) => {
  const onDismissClick = () => {
    if (onDismiss) {
      onDismiss()
    }
    toggle()
  }
  return (
    <Modal
      modalClassName={sideModal ? 'side-modal' : undefined}
      unmountOnClose
      isOpen={isOpen}
      toggle={onDismissClick}
    >
      <ModalHeader toggle={onDismissClick}>{title}</ModalHeader>
      <ModalBody className={className}>{children}</ModalBody>
    </Modal>
  )
}

export default ModalDefault
