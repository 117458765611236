import { FC } from 'react'
import Calendar, { CalendarProps } from 'react-calendar'

type DatePickerType = CalendarProps & {}

const DatePicker: FC<DatePickerType> = (props) => {
  return (
    <>
      <Calendar
        formatShortWeekday={(_, d) => {
          return d.toLocaleString('en', { weekday: 'short' }).slice(0, 2)
        }}
        showNeighboringMonth
        {...props}
      />
    </>
  )
}

export default DatePicker
